import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { parseACF } from '../utils'
import DefaultPage from '../templates/default-page'

const Index = props => {
    const data = parseACF(useStaticQuery(query), 'allWordpressDefaultpages')
    // console.log(data)

    return (
        <DefaultPage pageData={data} />
    )
}

export const query = graphql`
    query {
        allWordpressDefaultpages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
